import { Props, Playground } from 'docz';
import Gallery from 'react-photo-gallery';
import cloneDeep from 'lodash.clonedeep';
import { GooglePhoto } from "../../../../../src/index.tsx";
import "../../../../../styles.css";
import "../../../../../docs/examples/custom-animation.css";
import * as React from 'react';
export default {
  Props,
  Playground,
  Gallery,
  cloneDeep,
  GooglePhoto,
  React
};